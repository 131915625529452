<template>
  <div class="mini-release-config">
    <div class="header-line">
      <div class="back-div" @click="routeBack">
        <img src="@/assets/images/go_back_icon.png" alt="" />
        <span>返回</span>
      </div>
      <div class="border-line"></div>
      <div class="name-div">
        <span class="name-label">
          {{ `${form.miniprogramTitle} - 发布记录` }}
        </span>
      </div>
    </div>
    <div class="main-container">
      <div class="left-part">
        <div class="title-label">发布记录</div>
        <div class="no-data" v-if="!cardList.length">暂无发布记录...</div>
        <div class="card-list" v-else v-loading="loading">
          <div
            :class="['single-card', item.active && 'single-card-active']"
            v-for="item in cardList"
            :key="item.versionId"
            @click="setActiveCard(item)"
          >
            <div class="title-line" :title="item.miniprogramTitle">
              {{ `${item.updateUserName} 发布了 ${item.miniprogramTitle}` }}
            </div>
            <div class="date-line">
              {{ item.lastUpdateDate | dateFormat("YYYY-MM-DD HH:mm:ss") }}
            </div>
            <div class="desc-line">
              <span class="description-label">发布描述：</span>
              <div class="inner">
                {{ item.publishDescription || "暂无发布描述" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-part">
        <div class="button-line" v-if="this.cardList.length">
          <r-button @click="releaseBack">还原</r-button>
          <r-button plain @click="copyHandler">创建副本</r-button>
        </div>
        <div class="preview-line">
          <SingleMiniPreview
            v-if="activeItem.pageConfig"
            :dataset="{
              datas: activeItem,
            }"
            :height="750"
          />
        </div>
        <!-- <div class="description-line">
          <div class="description-label">发布描述：</div>
          <div class="inner">
            {{ activeItem.publishDescription || "暂无发布描述" }}
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getMiniPublishRecordsById } from "@/api/ruge/lego/config";
import SingleMiniPreview from "../config/components/singleMiniPreview.vue";
import {
  saveMiniprogram,
  getMiniRecordVersionById,
} from "@/api/ruge/lego/config";

export default {
  name: "mini-release-config",
  components: {
    SingleMiniPreview,
  },
  data() {
    return {
      loading: false,
      form: {
        miniprogramTitle: null,
      },
      cardList: [],
      activeItem: {},
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    themeRender(datas) {
      try {
        const parsedItem = JSON.parse(datas.pageConfig);
        return parsedItem.theme;
      } catch (e) {
        return "blue";
      }
    },
    initDatas(setName) {
      const { miniprogramId, miniprogramTitle } = this.$route.query;
      this.form.miniprogramTitle = setName || miniprogramTitle;
      getMiniPublishRecordsById({ miniprogramId }).then((res) => {
        this.cardList = res;
        if (res && res.length) {
          this.setActiveCard(res[0]);
        }
      });
    },
    routeBack() {
      const { miniprogramId } = this.$route.query;
      this.$router.push({
        path: "/lego/config/list",
        query: {
          miniprogramId,
        },
      });
    },
    setActiveCard(setItem) {
      this.cardList.forEach((item, index) => {
        if (item.versionId === setItem.versionId) {
          item.active = true;
          this.activeIndex = index;
        } else {
          item.active = false;
        }
      });
      this.activeItem = {};
      this.$nextTick(() => {
        this.activeItem = setItem;
      });
    },
    async releaseBack() {
      await this.$confirm(`是否确认还原? `, this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: this.$t("commons.cancel"),
        type: "warning",
      });
      this.releaseGoBack();
    },
    async releaseGoBack() {
      const { versionId, miniprogramId } = this.activeItem;
      const { areaId } = this.$route.query;
      const versionDetails = await getMiniRecordVersionById({
        versionId,
        miniprogramId,
      });
      const params = {
        ...versionDetails,
        ...{
          miniprogramStatus: "DRAFT", // 还原状态都改为draft
          areaId,
        },
      };
      saveMiniprogram(params).then(() => {
        this.$message.success("还原成功");
        this.form.miniprogramTitle = this.activeItem.miniprogramTitle;
      });
    },
    async copyHandler() {
      const { versionId, miniprogramId } = this.activeItem;
      const versionDetails = await getMiniRecordVersionById({
        versionId,
        miniprogramId,
      });
      const params = {
        ...versionDetails,
        ...{
          miniprogramId: null,
          miniprogramStatus: "DRAFT", // 还原状态都改为draft
          miniprogramTitle: `${versionDetails.miniprogramTitle}-复制`,
        },
      };
      saveMiniprogram(params).then((res) => {
        this.$router.push({
          path: "/lego/config/edit",
          query: {
            layout: "hide",
            miniprogramId: res.miniprogramId,
          },
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.mini-release-config {
  background: #f7f8fa;
  .header-line {
    height: 70px;
    line-height: 70px;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 0 40px;
    border-bottom: 1px solid #e3e8ee;
    .back-div {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 18px;
      color: #2a61ff;
      img {
        margin-right: 4px;
        position: relative;
        top: -1px;
      }
      span {
        font-weight: 600;
      }
    }
    .border-line {
      width: 1px;
      height: 12px;
      background: #cbdbe9;
      margin: 0 10px;
    }
    .name-div {
      display: flex;
      align-items: center;
      .name-label {
        display: inline-block;
        max-width: 375px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
        font-size: 18px;
        color: #252d3d;
      }
    }
  }
  .main-container {
    height: calc(100vh - 70px);
    width: 100%;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    .left-part {
      width: 360px;
      height: 100%;
      background: #ffffff;
      border-radius: 4px;
      padding: 20px 15px;
      .title-label {
        font-weight: 600;
        font-size: 16px;
        color: #252d3d;
        height: 30px;
        line-height: 18px;
      }
      .no-data {
        padding: 10px 0;
      }
      .card-list {
        height: calc(100% - 30px);
        overflow: auto;
        .single-card {
          width: 320px;
          border-radius: 10px;
          background: #f7f8fa;
          border: 1px solid #f9f9f9;
          margin-bottom: 12px;
          cursor: pointer;
          padding: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title-line {
            font-weight: 600;
            font-size: 14px;
            color: #252d3d;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .date-line {
            font-weight: 400;
            font-size: 14px;
            color: #a7b4c7;
            margin: 10px 0 15px;
          }
          .border-line {
          }
          .desc-line {
            .inner {
              line-height: 20px;
              text-indent: 65px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              font-size: 12px;
            }
            .description-label {
              font-size: 12px;
              font-weight: bold;
              position: absolute;
              top: 4px;
              left: 0px;
            }
            position: relative;
            width: 100%;
            border-radius: 10px;
            color: #5d687c;
            overflow: auto;
            font-size: 14px;
          }
        }
        .single-card-active {
          border: 1px solid #2a61ff;
        }
      }
    }
    .right-part {
      flex: 1;
      margin-left: 20px;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 30px;
      position: relative;
      .button-line {
        margin-bottom: 20px;
        text-align: right;
        position: absolute;
        top: 20px;
        right: 20px;
      }
      .preview-line {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>