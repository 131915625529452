var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mini-release-config" }, [
    _c("div", { staticClass: "header-line" }, [
      _c("div", { staticClass: "back-div", on: { click: _vm.routeBack } }, [
        _c("img", {
          attrs: { src: require("@/assets/images/go_back_icon.png"), alt: "" },
        }),
        _c("span", [_vm._v("返回")]),
      ]),
      _c("div", { staticClass: "border-line" }),
      _c("div", { staticClass: "name-div" }, [
        _c("span", { staticClass: "name-label" }, [
          _vm._v(
            "\n        " +
              _vm._s(`${_vm.form.miniprogramTitle} - 发布记录`) +
              "\n      "
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "main-container" }, [
      _c("div", { staticClass: "left-part" }, [
        _c("div", { staticClass: "title-label" }, [_vm._v("发布记录")]),
        !_vm.cardList.length
          ? _c("div", { staticClass: "no-data" }, [_vm._v("暂无发布记录...")])
          : _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass: "card-list",
              },
              _vm._l(_vm.cardList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.versionId,
                    class: ["single-card", item.active && "single-card-active"],
                    on: {
                      click: function ($event) {
                        return _vm.setActiveCard(item)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "title-line",
                        attrs: { title: item.miniprogramTitle },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              `${item.updateUserName} 发布了 ${item.miniprogramTitle}`
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "date-line" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("dateFormat")(
                              item.lastUpdateDate,
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                    _c("div", { staticClass: "desc-line" }, [
                      _c("span", { staticClass: "description-label" }, [
                        _vm._v("发布描述："),
                      ]),
                      _c("div", { staticClass: "inner" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(item.publishDescription || "暂无发布描述") +
                            "\n            "
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
      ]),
      _c("div", { staticClass: "right-part" }, [
        this.cardList.length
          ? _c(
              "div",
              { staticClass: "button-line" },
              [
                _c("r-button", { on: { click: _vm.releaseBack } }, [
                  _vm._v("还原"),
                ]),
                _c(
                  "r-button",
                  { attrs: { plain: "" }, on: { click: _vm.copyHandler } },
                  [_vm._v("创建副本")]
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "preview-line" },
          [
            _vm.activeItem.pageConfig
              ? _c("SingleMiniPreview", {
                  attrs: {
                    dataset: {
                      datas: _vm.activeItem,
                    },
                    height: 750,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }